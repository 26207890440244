import { css } from 'linaria';
import { theme } from '../../Theme';
import { styled } from 'linaria/react';

export const LoginWrapper = styled.div`
  margin: 3rem auto 5rem;
  max-width: 500px;
  padding: 0 2rem;
  > form {
    width: 100%;
  }
  button {
    width: 100%;
  }
`;

export const smallSection = css`
  display: flex;
  flex-direction: column;
  padding: ${theme.space[1]};
  width: 30rem;
  max-width: 100%;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = css`
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: ${theme.colors.red};
    transition: opacity 0.4s ease;
  }
`;
