import SignupFormProvider from '@jetshop/core/components/Auth/SignupFormContainer';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import t from '@jetshop/intl';
import qs from 'qs';
import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { useLocation } from 'react-router';
import BaseMaxWidth from '../../Layout/MaxWidth';
import LoadingPage from '../../LoadingPage';
import { smallCaps } from '../../ui/Headings';
import Address from '../Signup/Address';
import LoginFields from '../Signup/LoginFields';
import { theme } from '../../Theme';
import { useSiteContent } from '../../Util/useSiteContent';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import cartQuery from '../../Cart/CartQuery.gql';

const MaxWidth = styled(BaseMaxWidth)`
  align-items: center;
  p {
    line-height: 1.5;
  }
  button {
    width: 100%;
    max-width: 100%;
  }
  .hint {
    margin-top: 0.5rem;
    font-style: italic;
    font-size: 0.75rem;
    text-align: center;
  }
`;
const Section = styled.section`
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 55rem;
  margin-bottom: 3rem;
  > header {
    width: 100%;
    flex-grow: 1;
  }
  > div {
    width: 48%;
  }
  ${theme.below.md} {
    display: block;
    width: 80%;
    > div {
      width: 100%;
    }
  }
  ${theme.below.sm} {
    width: 100%;
  }
`;

function HeaderWithStatus({ status }) {
  if (!status) {
    return (
      <>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }

  if (status.isAdditionalDataRequired) {
    return (
      <>
        <p>{t("We're missing some key information.")}</p>
        <p>{t('Please fill in required fields to continue.')}</p>
      </>
    );
  }

  if (status.isNonExistingCustomer) {
    return (
      <>
        <p>
          {t('No customer found with entered { lookupKey }.', {
            lookupKey: 'e-mail'
          })}
        </p>
        <p>
          {t(
            'Please fill in your contact details for shipping and communication.'
          )}
        </p>
      </>
    );
  }
}

function VoyadoSignup() {
  const { loggedIn } = useAuth();
  const { routes } = useShopConfig();
  const { state, search } = useLocation();
  const item = useSiteContent('SignupText');

  const searchObject = qs.parse(search, { ignoreQueryPrefix: true });
  const goToCheckout = searchObject.checkout === 'true';

  if (loggedIn) {
    if (goToCheckout) {
      return (
        <CartProvider query={cartQuery}>
          {result => {
            const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
            window.location.href = checkoutUrl;
            return false;
          }}
        </CartProvider>
      );
    }

    return <Redirect to={routes.myPages.path} />;
  }

  return (
    <SignupFormProvider
      LoadingComponent={<LoadingPage />}
      lookupData={state?.customer}
    >
      <MaxWidth style={{ marginTop: '2em' }}>
        <Section>
          <Intl>{t => <Head data={{ title: t('Signup') }} />}</Intl>
          <header>
            <h1
              className={cx('heading', smallCaps)}
              data-testid="signup-page-header"
            >
              {t('Sign up')}
            </h1>
            <HeaderWithStatus status={state?.status} />
          </header>
          <div>
            <Address />
          </div>
          <div>
            <LoginFields />
            <div style={{ marginTop: '1rem' }}>{item && item.html}</div>
          </div>
        </Section>
      </MaxWidth>
    </SignupFormProvider>
  );
}
export default VoyadoSignup;
