import React from 'react';
import { Formik } from 'formik';
import Input from '../../Forms/Input';
import TrendButton from '../../ui/Button';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { CustomerStatusHeaderText } from './VoyadoLookup';

const LookupFieldContainer = styled.section`
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  dl {
    display: flex;
    flex-flow: row wrap;
  }
  dt {
    flex-basis: 50%;
    padding: 2px 4px;
    text-align: right;
    font-weight: 600;
    ::after {
      content: ':';
    }
  }
  dd {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
`;

export const ExternalLookupField = ({ lookup, isLookupPending }) => {
  return (
    <>
      <CustomerStatusHeaderText>
        {t(
          'Enter your { lookupKey } and we will check if you are an existing member or not.',
          { lookupKey: 'e-mail' }
        )}
      </CustomerStatusHeaderText>
      <Formik
        initialValues={{ lookupKey: '' }}
        onSubmit={(values, actions) => {
          lookup(values.lookupKey);
        }}
      >
        {({ values, handleChange, handleBlur, handleSubmit }) => {
          return (
            <LookupFieldContainer>
              <form onSubmit={handleSubmit}>
                <Input
                  label={'E-mail'}
                  name="lookupKey"
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TrendButton
                  data-testid="commit-lookup"
                  type="submit"
                  loading={isLookupPending}
                  disabled={isLookupPending || values.lookupKey.length === 0}
                >
                  {t('Login')}
                </TrendButton>
              </form>
            </LookupFieldContainer>
          );
        }}
      </Formik>
    </>
  );
};
