import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { useVoyadoLookup } from '@jetshop/flight-voyado';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';

import { GlobalError } from '../../Forms/GlobalError';
import Input from '../../Forms/Input';
import MaxWidth from '../../Layout/MaxWidth';
import TrendButton from '../../ui/Button';
import { smallSection } from '../UI/Form';
import { theme } from '../../Theme';
import { ExternalLookupField } from './VoyadoLookupField';

export const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  min-width: 155px;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin-top: 3rem;
  margin-bottom: 5rem;
  &.max-width {
    align-items: unset;
    max-width: 500px;
    ${theme.below.md} {
      width: 90%;
    }
  }
`;

const LoginPageHeader = styled('header')`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const CustomerStatusHeaderText = styled('h4')`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
`;

function VoyadoLookup() {
  const { ...voyado } = useVoyadoLookup({});
  const { routes } = useShopConfig();
  const { logIn, loggedIn } = useAuth();

  console.log(routes);

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  if (voyado.isAdditionalDataRequired) {
    return (
      <Redirect
        to={{
          pathname: routes.signUp.path,
          state: {
            customer: {
              ...voyado.customer
            },
            status: {
              isAdditionalDataRequired: voyado.isAdditionalDataRequired
            }
          }
        }}
      />
    );
  }

  if (voyado.isNonExistingCustomer) {
    return (
      <Redirect
        to={{
          pathname: routes.signUp.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isNonExistingCustomer: voyado.isNonExistingCustomer
            }
          }
        }}
      />
    );
  }

  if (voyado.isInActivation) {
    const {
      isActivationRequired,
      isActivationPending,
      isActivationSuccess
    } = voyado;
    return (
      <LogInPageMaxWidth className="max-width">
        <CustomerStatusHeaderText>
          {isActivationRequired &&
            t(
              'You exist as an member, click to activate your account. We will send you an email with a link on how to set your password.'
            )}
          {isActivationSuccess &&
            t('We have successfully activated your account.')}
          {isActivationPending && t('Activating account')}
        </CustomerStatusHeaderText>
        <StyledTrendButton
          disabled={isActivationPending}
          loading={isActivationPending}
          onClick={() => {
            if (isActivationRequired) {
              voyado.activate();
            } else {
              logIn(voyado.customer.token);
            }
          }}
        >
          {isActivationRequired && t('Activate')}
          {isActivationPending && t('Activating')}
          {isActivationSuccess && t('Login')}
        </StyledTrendButton>
      </LogInPageMaxWidth>
    );
  }

  if (voyado.isPreExistingCustomer) {
    return (
      <LogInPageMaxWidth>
        <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
        <LogInFormProvider
          redirect="my-pages"
          initialEmail={voyado?.customer?.emailAddress.masked}
        >
          {({ globalError, isSubmitting, isValid }) => (
            <section className={cx(smallSection)}>
              <LoginPageHeader>
                <CustomerStatusHeaderText>
                  {t('We found your account, enter your password to continue.')}
                </CustomerStatusHeaderText>
              </LoginPageHeader>

              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {globalError}
                </GlobalError>
              )}

              <section className={actionWrapper}>
                <StyledTrendButton
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                <TextLink to={routes.forgotPassword.path}>
                  {t('Forgot password?')}
                </TextLink>
              </section>
            </section>
          )}
        </LogInFormProvider>
      </LogInPageMaxWidth>
    );
  }

  return (
    <LogInPageMaxWidth className="max-width">
      <ExternalLookupField {...voyado} />

      {Object.values(voyado.error).some(Boolean) && (
        <p>{t('Something went wrong. Please try again')}</p>
      )}
    </LogInPageMaxWidth>
  );
}

export default VoyadoLookup;
